import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const RemunerationPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <LandingBanner title="Remuneration Policy" alt />
      <section className="internal-page__section">
        <h2>1. Introduction</h2>
        <p>
          W&W Asset Management Ireland DAC (“WWAMI” or the “Firm”) remuneration policies and
          procedures are consistent with:
        </p>
        <ul>
          <li>ESMA Guidelines on sound remuneration policies under the AIFMD;</li>
          <li>ESMA Guidelines on sound remuneration policies under the UCITS Directive; and</li>
          <li>
            The Firm’s strategies for sustainability and Environmental, Social, & Corporate
            Governance (“ESG”) and the requirements of the Sustainable Finance Disclosure Regulation
            (EU) 2019/2088 ("SFDR").
          </li>
        </ul>
        <p>
          Our policy promotes sound and effective risk management and does not encourage risk-taking
          that may be inconsistent with the risk profile of the Firm. The Firm ensures that any
          Delegate to whom such requirements also apply pursuant to the ESMA Remuneration Guidelines
          will have equivalent remuneration policies and practices in place. The Head of Compliance
          ensures the Remuneration Policy is reviewed by the Board on at least an annual basis.
        </p>
        <p>
          In compliance with SFDR the Firm has published on its website the strategies used for
          integrating sustainability risks into the investment decision-making processes and the
          main adverse sustainability impacts of investment decisions on sustainability factors. The
          Firm’s remuneration policy is in line with the Firm’s and W&W Group's business strategy
          and its sustainability mission statement. The business strategy incorporates corporate
          values and culture and is geared towards long-term and sustainable management and the
          assumption of social responsibility.
        </p>
        <h2>2. Categories of staff</h2>
        <p>
          In accordance with ESMA Guidelines, WWAMI has identified individuals whose professional
          activities have a material impact on the Firm’s risk profile as well as risk profile of
          the UCITS and AIF it manages. The “identified staff” includes:
        </p>
        <ul>
          <li>Executive Directors; and</li>
          <li>Senior Management.</li>
        </ul>
        <p>
          Non-executive Directors receive a fixed fee only and do not receive performance-based
          remuneration therefore avoiding potential conflict of interest.
        </p>
        <h2>3. Remuneration structure</h2>
        <p>
          Responsibility of overseeing the renumeration ultimately sits with the Board of Directors.
          The Executive is subsequently tasked with the implementation of the remuneration policy
          and guidelines, and this responsibility directly sits with the Board for senior managers
          in the Risk Management and Compliance functions.
        </p>
        <p>A remuneration takes a form of:</p>
        <div>
          <ul>
            <li>
              <span>Fixed remuneration</span>

              <span>
                is determined on the basis of the role of the individual employee, including
                professional experience, qualifications, responsibility, job complexity, and local
                market conditions and is specified in the employee’s contract of employment. In line
                with encouraging prudent risk management and avoiding conflicts of interest
                fixed-remuneration
              </span>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <span>Variable remuneration</span>

              <span>
                is performance-based remuneration that motivates and rewards employees in their
                specific roles, that enables WWAMI to meet its objectives, such as strengthening
                long-term customer relations, sustainability, generating income and shareholder
                value and meeting its statutory and regulatory obligations.
              </span>
            </li>
          </ul>
        </div>
        <p>
          The variable remuneration, if any, may be performance-related and is paid at the Company’s
          discretion. i.e. variable remuneration is not guaranteed and is based on a combination of
          the individual employee’s performance and the overall performance the Firm.
        </p>
        <p>
          The measurement of performance, as a basis for variable remuneration, may include a
          downward adjustment for exposure to current and future risks, meeting sustainability and
          ESG objectives, the performance of an individual employee considering financial and
          non-financial criteria.
        </p>
        <p>
          Variable remuneration includes both individual and firmwide business objectives, where
          applicable, designed to promote the long terms success of the Firm and meet certain
          sustainability and ESG targets. The level of payment of variable remuneration may be
          adjusted to the extent these targets are achieved.
        </p>
        <p>
          The Board of Directors determines a maximum percentage of performance-based remuneration
          relative to the fixed remuneration and a deferred component of 3 to 6 years aligned with
          the Firm’s risk profile, time horizon and the activities of the individual employee.
        </p>
        <p>
          The variable part of remuneration of employees engaged in the control functions such as
          the Risk, Compliance or Internal Audit functions shall be independent from the operational
          performance of the Firm to preserve independence of the control functions.
        </p>
        <div>
          <ul>
            <li>
              <span>Pension contribution</span>
              <span>
                WWAMI has established an occupational pension scheme. The scheme is a defined
                contribution scheme, to which the Firm contributes a percentage of each employees’
                annual salary. The level of the Firm’s contribution is determined by the Board of
                Directors.
              </span>
            </li>
          </ul>
        </div>
        <h2>4. Disapplication of certain provisions of the ESMA Guidelines</h2>
        <p>
          In accordance with the proportionality provisions and of the Regulations, together with
          the risk profile, appetite and risk strategy of the Manco and each of its Managed Funds,
          taking into account a non-exhaustive combination of its size, nature and the, scope and
          complexity of its activities, together with any other relevant criteria and within the
          limits of the ESMA Guidelines, the Board has dis-applied the following requirements of the
          ESMA Regulations and the Guidelines in relation to the following:
        </p>
        <ul>
          <li>variable remuneration in instruments;</li>
          <li>retention;</li>
          <li>deferral;</li>
          <li>
            ex post incorporation of risk for variable remuneration (together, with the immediately
            foregoing the Pay-out Process Rules; and
          </li>
          <li>the requirement to establish a remuneration committee (see below).</li>
        </ul>
        <p>
          With regard to the ESMA UCITS Guidelines, the Board notes that these guidelines (unlike
          the ESMA AIFMD Guidelines) do not offer any guidance as to whether the application of the
          proportionality principle may enable management companies such as the Manco to dis-apply
          the specific requirements above, however, in a letter to the European Union law-making
          institutions published alongside the ESMA UCITS Guidelines (the “ESMA Letter”), ESMA set
          out its view that it should be possible to dis-apply the requirements relating to the
          pay-out process under specific circumstances and that it should also be possible to apply
          lower thresholds whenever minimum quantitative thresholds are set for the pay-out
          requirements. In the absence of legislative amendment at European level, or clarification
          at member state level, the Manco has made its own assessment as to the application of the
          proportionality principle and in doing so has deemed it appropriate to have regard to
          ESMA’s views in the ESMA Letter regarding the circumstances in which the principle of
          proportionality may be relied upon.
        </p>

        <h2>5. Remuneration Committee</h2>
        <p>
          <span>
            Taking into account its size, internal organisation and the nature, scope and complexity
            of its activities, the Firm has dis-applied the requirement to establish a remuneration
            committee.
          </span>
        </p>
        <p>
          <span>
            In deciding to dis-apply this requirement, the Firm has taken into account the following
            considerations in relation to proportionality, insofar as they relate to the Firm and
            its activities and the funds under management. The Firm reviews the below considerations
            on an annual basis and acknowledges that as the Firm's activities expand and as it grows
            in size, that it may be appropriate to apply the requirement to establish a remuneration
            committee at a later date.
          </span>
        </p>
        <div>
          <ul>
            <li>
              <span>Size:</span>
              <span>
                Due to the nature, scale and complexity of the Firm's business and determined that
                overall, its business activities are currently at a low level of risk when compared
                against other investment companies. The Firm has a risk framework in place to
                effectively manage the portfolio risks of the funds under management.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <span>Internal Organisation:</span>
              <span>
                The Firm was incorporated in Ireland on April 30, 1999 as a private company with
                limited liability under the Companies Act 1963 to 2003. The Firm has converted to a
                Designated Activity Company (DAC) under Part 2 of the Companies Act, 2014 on 25
                August 2016, which was notified to the Central Bank. The Firm will not be listed on
                any regulated market or exchange. The current number of employees and Directors
                (outlined in the preceding paragraph) is far below 50. The organisational structure
                is not complex with clear reporting lines in place.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <span>Nature, scope and complexity of the activities:</span>
              <span>
                WWAMI is currently appointed as manager to, 2 umbrella QIAIF and 2 umbrella UCITS.
                The Firm has not sought authorisation to carry on individual portfolio management
                services. The funds under management are domiciled in Ireland and the investment
                strategies of the funds are not complex, the funds under management predominantly
                have relatively low risk profiles, long-term investment objectives, low levels of
                levels of leverage and low-frequency trading.
              </span>
            </li>
          </ul>
        </div>
        <h2>6. Remuneration Report</h2>
        <p>
          <span>
            Any breaches of the Remuneration policy will be reported to the Board. The Remuneration
            Report is issued annually by the Board, containing details of any breaches of the Firm’s
            remuneration policies and procedures and remedial action to be undertaken. If the Firm
            identifies that its remuneration policies and procedures are not being adhered to, the
            details will be brought to the Board’s attention by the Managing Director or by
            Compliance. Following a review of the issue, the Board shall determine the best course
            of action and ensure it is carried out.
          </span>
        </p>
        <p>
          <span>
            Exceptional Reporting may occur if extreme remuneration issues are reported by
            Compliance to the Board on an exceptional basis where such issues should be considered,
            and these issues require addressing outside of a standard reporting procedure. This may
            involve the convening of specially scheduled board meeting in certain circumstances.
          </span>
        </p>
        <p>
          <span>
            This document forms the written element of the remuneration policy for the Firm and
            should be read in conjunction with the Firm’s regulatory framework documentation and in
            particular the Firm's Programme of Activity. The Remuneration Policy reflects the Firm's
            objective for good corporate governance, including sustainability and ESG objectives,
            and:
          </span>
        </p>
        <ol>
          <li>
            is consistent with and promotes sound and effective risk management and does not
            encourage risk-taking which is inconsistent with the risk profile, rules or
            constitutional documents of the Funds under management; and
          </li>
          <li>
            is consistent with the business strategies, objectives, values and interests of the
            Funds under management and includes measures to avoid conflicts of interest.
          </li>
        </ol>
        <h2>7. Roles and Responsibilities</h2>
        <p>
          The Board is responsible for ensuring that the Firm’s overall Remuneration Policy and
          framework is consistent with the strategic objectives of the Firm and takes account of
          risk management implications.
        </p>
        <p>
          The Executive is responsible for implementation and effective operation of the
          Remuneration Policy and ensures that the Firm’s remuneration structures are compliant with
          requirements of this policy.
        </p>
        <p>
          The Compliance and Risk functions are responsible for analysing how the Remuneration
          Policy affects the Firm’s compliance with legislation, regulations and internal policies
          and risk profile. The second line of defence is responsible for reporting all identified
          risk and issues of non-compliance to the management body.
        </p>
        <p>
          The Internal Audit function carries out an independent review of the design,
          implementation and effects of the Remuneration policy on its risk profile and the way
          these effects are managed.
        </p>
        <h2>8. Risk Management</h2>
        <p>
          The Remuneration Policy is consistent with and promotes sound and effective risk
          management by:
        </p>
        <ol>
          <li>
            having a business model which by its nature does not promote excessive risk taking;
          </li>
          <li>
            defining performance goals and objectives for the funds under management which are
            aligned with the business; and
          </li>
          <li>
            ensuring that the fixed salary element of those involved in relevant functions reflects
            the market rate.
          </li>
        </ol>
        <h2>9. Conflicts of Interest</h2>
        <p>
          The Remuneration policy ensures that there is no explicit or appearance of a conflict of
          interest between the firm and all relevant stakeholders. This is supported by various
          levels of oversight and controls, including; the Conflicts of Interest Policy, Conflicts
          of Interest Log, Staff and Director declarations and attestations, as well as external
          public and legal searches to support declarations for Fitness &amp; Probity and Conflict
          of Interest verification.
        </p>
        <h2>10. Review</h2>
        <p>
          The Remuneration Policy (together with compliance herewith) will be subject to an annual
          internal review by the Managing Director and Compliance. Given the nature, scale and
          complexity of the Firm's activities, the Remuneration Policy will not be subject to an
          external, independent review. The review by Compliance will ensure that:
        </p>
        <ol>
          <li>The overall remuneration system operates as intended;</li>
          <li>The remuneration pay-outs are appropriate;</li>
          <li>
            The risk profile, long term objectives and goals of the UCITS and AIFs are adequately
            reflected; and
          </li>
          <li>
            The policy reflects best practice guidelines and regulatory requirements. Compliance and
            the Board will take appropriate measures to address any deficiencies identified in the
            Remuneration Policy.
          </li>
        </ol>
        <h2>10. Alignment with long term interests &amp; sustainability</h2>
        <p>
          <span>
            This Remuneration Policy focuses on sustaining a long-term value creation for
            shareholders and promoting sound and effective risk management through a stringent
            governance structure for setting goals and communicating these goals to employees and
            secondees. The policy ensures that remuneration is linked to the Firms strategies for
            sustainability and ESG and, where possible, variable remuneration reflects achievement
            of those targets. It incorporates measures to avoid conflicts of interest, including
            both financial and non-financial goals in performance and result assessments and making
            non-performance related remuneration the main remuneration component.
          </span>
        </p>
        <p>
          <span>
            The Remuneration Policy reflects the Firm's alignment of the long-term interests of the
            Firm, its employees, secondees and clients. In addition, it ensures that:
          </span>
        </p>
        <ol>
          <li>
            the Firm is able to attract, develop and retain high-performing and motivated employees
            / secondees in a competitive, international market;
          </li>
          <li>employees / secondees are offered a competitive remuneration package; and</li>
          <li>
            employees / secondees feel encouraged to create sustainable results in line with the
            long-term interests of the Company and its clients.
          </li>
        </ol>
      </section>
    </Layout>
  );
};

export default RemunerationPage;
